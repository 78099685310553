const ERROR_MESSAGE = {
  ALERT_RED: 'alert-red',
  DEFAULT: 'DEFAULT',
  MAINTENANCE: 'MAINTENANCE',
  NOPERMISSION: 'NOPERMISSION',
  NOTRADINGPERMISSION: 'NOTRADINGPERMISSION',
  INVALIDPARAMETER: 'INVALIDPARAMETER',
  INVALID_PARAMETER: 'INVALID_PARAMETER',
  INVALID_RECAPTCHA: 'INVALID_RECAPTCHA',
  INVALID_LOGIN: 'INVALID_LOGIN',
  ERR_SYSTEM: 'ERR_SYSTEM',
  ERR_INVALID_PARAMS: 'ERR_INVALID_PARAMS',
  ERR_ORDER_FAIL: 'ERR_ORDER_FAIL',
  ERR_PIN_INVALID: 'ERR_PIN_INVALID',
  ERR_LOGIN_FAIL: 'ERR_LOGIN_FAIL',
  INVALID_AMEND: 'INVALID_AMEND',
  INVALID_PIN: 'INVALID_PIN',
  SYSTEMERROR: 'SYSTEMERROR',
  NOTFOUND: 'NOTFOUND',
  NOSUBSCRIPTION: 'NOSUBSCRIPTION',
  PAYWALL_ERROR: 'PAYWALL',
  PAYWALL_NOSUBSCRIPTION: 'NO_SUBSCRIPTION',
  NOPHONEVERIFY: 'NOPHONEVERIFY',
  UNAUTHORIZED: 'UNAUTHORIZED',
  FORBIDDEN: 'FORBIDDEN',
  SERVICE_BUSY: 'SERVICE_BUSY',
  INVALID_SESSION: 'INVALID_SESSION',
  ERR_LOGIN_AUTHORIZATION: 'ERR_LOGIN_AUTHORIZATION',
  ERR_PIN_AUTHORIZATION: 'ERR_PIN_AUTHORIZATION',
  ERR_UNKNOWN_BROKER: 'ERR_UNKNOWN_BROKER',
  INCORRECT_PIN: 'INCORRECT_PIN',
  INVALID_CREDS: 'INVALID_CREDS',

  UNKNOWN_SYSTEM_ERROR: 'Kondisi Internet Kamu Sedang Tidak Stabil.',
  OFFLINE: 'Kondisi internet kamu sedang tidak stabil',

  DEFAULT_ERROR_MESSAGE:
    'Something is terribly wrong, please contact our administrator',
  PERMISSION_DENIED:
    'You do not have enough permission to access the resources',

  // error message upload avatar onboarding
  UPLOAD_FAILED: 'Upload Failed',
  PLEASE_LOAD_IMAGE: 'Please load an image',
  UPLOAD_IMAGE_TYPE_NOT_SUPPORTED:
    'Only image file (type: JPEG, JPG, PNG, and GIF) allowed',
  UPLOAD_DOCUMENT_TYPE_NOT_SUPPORTED:
    'Only document file (type: PDF, DOC, DOCX, PPT, PPTX, XLS and XLSX) allowed',
  MAX_FILE_SIZE: 'Max File Size 2Mb',
  INVALID_AVATAR_EXTENSION:
    'Only image file (type: JPEG, JPG, and PNG) allowed',

  // stream error message
  DOCUMENT_IMAGE_NOT_ALLOWED:
    'Image files (such as jpg, jpeg, png, gif) are not allowed to be uploaded from "Attach Document" input',
  MAX_UPLOAD_FILES: 'Maximum of 1 file uploads allowed',
  STREAM_TEXT_REQUIRED: 'Text is required in posts',
  STREAM_TARGET_PRICE_REQUIRED: 'Target Price is required in posts',
  STREAM_ERROR_UPLOAD_FILE: 'Upload file error',
  ACADEMY_ERROR_MESSAGE: 'Failed to open Academy, Please reload this page',

  // Screener
  SCREENER_SPECIFY_RULES: 'Please enter at least one screening rule',
  SCREENER_SPECIFY_RULES_VALUE: 'Please enter a value on your screening rule',

  // Tipping
  TIPPING_MAX_AMOUNT_ERROR: 'Jumlah Maksimal Tip Rp 10,000,000',

  // Fundachart
  MAX_METRICES_AND_SYMBOLS:
    'Harap pilih tidak lebih dari total 10 parameter (perusahaan/metrik) untuk bagan',
  // Chartbit
  LOAD_TEMPLATE: 'Failed to load template',
  SAVE_TEMPLATE: 'Failed to save template',
};

export default ERROR_MESSAGE;
